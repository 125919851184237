.animacion {
  height: 60vh;
  background-color: #000000;
  background-image: url(../assets/bg-dark.jpg);
  display: flex;
  object-fit: cover;
}

.grafico {
  color: #fcfcf4;
  width: 100%;
  padding: 20px;
}

.grafico .contenido {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  overflow: auto;
}

.img-fluid {
  transform: scale(0.5);
  flex: 1 1 auto;
}

.estado {
  width: 60%;
  text-align: center;
  justify-self: center;
  position: absolute;
  font-size: 1.1em;
  padding: 10px 15px;
  background-color: rgb(10, 10, 10);
  border-bottom: 1px solid rgb(44, 95, 172);
  border-radius: 0 0 20px 20px;
  opacity: 0.8;
  cursor: context-menu;
}

.estado:hover {
  opacity: 1;
  transition: 200ms ease-in-out all;
}
