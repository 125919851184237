.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #757575;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid rgb(32, 182, 252);
  appearance: none;
  width: 10px;
  height: 20px;
  border-radius: 50%;
  background: #4c63af;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  border: 1px solid rgb(7, 168, 243);
  width: 10px;
  height: 20px;
  border-radius: 2px;
  background: #4c63af;
  cursor: pointer;
}

.input {
  background-color: rgb(17, 17, 17);
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(0, 67, 143);
}

.input .row.random {
  border-bottom: 2px solid #1a1a1a;
}

.go {
  margin: 10px 0;
}

.num {
  width: 50px;
  margin: 10px 20px;
}

.error {
  text-align: center;
  display: inline;
  margin-left: 50px;
  color: red;
}

.buscar {
  display: inline;
}

.insertar {
  margin-top: 30px;
  flex: 1 1 auto;
}

.velocidad {
  margin-top: 30px;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
}

.velocidad span {
  position: absolute;
  bottom: -20px;
  left: 60px;
}

.zoom {
  position: absolute;
  top: 5px;
  right: 40px;
  width: max-content;
  color: #fff;
  font-size: 1.5em;
}

.zoom-in:hover {
  color: #007bff;
}

.zoom-out:hover {
  color: #007bff;
}

.zoom-blocked {
  color: darkgrey;
}

.zoom-blocked:hover {
  color: darkgrey;
}
