@import "base.css";
@import "overrides.css";
@import "controls.css";
@import "display.css";

.contenedor {
  width: 85%;
  margin: 0 auto;
}

.cuadro {
  text-align: center;
  border-bottom: 1px solid rgb(71, 32, 245);
  height: 80px;
  min-width: 80px;
  flex-shrink: 0;
  font-size: 35px;
  padding: 10px 10px;
  margin: 20px;
  background-color: rgb(39, 0, 110);
  border-radius: 3px;
  /* transition: 500ms ease-in-out all; */
}

.cuadro small {
  font-size: 17px;
  color: rgb(180, 221, 255);
}

.pivotLoc {
  outline: 2px solid rgb(255, 174, 0);
  outline-offset: 8px;
  font-weight: 700;
}

.cuadro:hover {
  cursor: context-menu;
  opacity: 0.8;
  position: relative;
  top: 3px;
  transition: 200ms ease-in-out all;
}

.encontrado {
  background-color: rgb(7, 182, 30);
  border: 2px solid rgb(0, 255, 76);
  /* transition: 500ms ease-in-out all; */
}

.buscando {
  background-color: rgb(2, 91, 175);
  border: 2px solid rgb(95, 146, 255);
}

.pivote {
  background-color: rgb(196, 127, 0);
  border: 2px solid rgb(255, 157, 11);
}

.descartado {
  background-color: rgb(109, 0, 0);
  border: 2px solid rgb(51, 0, 0);
  color: #555;
}

.pseudo {
  margin-top: 20px;
  font-family: "Roboto Mono", monospace;
  background-color: #0e0e0e;
  font-size: 0.9rem;
  padding: 15px 25px;
  border: 3px solid #000;
  border-radius: 10px;
}

.leyenda-q {
  display: flex;
  align-items: center;
}

#override .leyenda-q .grupo div {
  min-width: 0;
  width: 40px;
  height: 40px;
  margin: 2px;
  padding: 0;
}

#footer {
  text-align: center;
  padding: 2em;
  margin-top: 2em;
}

#footer a {
  color: rgb(100, 100, 100);
  font-family: monospace;
}
