body {
  color: #f4f4f4;
  background-color: #161616;
  margin: 0;
  padding: 0;
}

main {
  margin-bottom: 20px;
}

header {
  background-color: #171717;
  align-items: center;
  padding: 1em;
  display: flex;
  box-shadow: 0 0 10px #0000005c;
}

header h1 {
  color: #d3f3fd;
  margin-left: .3em;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  width: 80px;
  color: #f4f4f4;
  border: none;
  border-bottom: 2px solid #5a6872;
  border-radius: 1px;
  padding: 5px 10px;
}

input {
  background-color: #303030;
}

input[type="number"]:hover {
  background-color: #242424;
  transition: all .2s ease-in-out;
}

input[type="number"]:focus {
  border-color: #129cec;
  outline: none;
}

pre {
  color: #f4f4f4;
  white-space: pre;
  font-size: .7rem;
}

#override .btn {
  border: 1px solid #0000;
  border-radius: 1px;
  padding: 8px 20px;
}

#override .btn-primary {
  background-color: #0f62fe;
}

#override .btn-primary:hover {
  background-color: #0353e9;
}

#override .row {
  margin: 0;
  padding: 0;
}

#override a {
  color: #f4f4f4;
}

#override .nav-link.active {
  color: #000;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  opacity: .7;
  -webkit-transition: all .2s;
  background: #757575;
  border-radius: 5px;
  outline: none;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 10px;
  height: 20px;
  cursor: pointer;
  background: #4c63af;
  border: 1px solid #20b6fc;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 20px;
  cursor: pointer;
  background: #4c63af;
  border: 1px solid #07a8f3;
  border-radius: 2px;
}

.input {
  background-color: #111;
  border-bottom: 1px solid #00438f;
  padding-bottom: 40px;
}

.input .row.random {
  border-bottom: 2px solid #1a1a1a;
}

.go {
  margin: 10px 0;
}

.num {
  width: 50px;
  margin: 10px 20px;
}

.error {
  text-align: center;
  color: red;
  margin-left: 50px;
  display: inline;
}

.buscar {
  display: inline;
}

.insertar {
  flex: auto;
  margin-top: 30px;
}

.velocidad {
  flex: auto;
  justify-content: center;
  margin-top: 30px;
  display: flex;
}

.velocidad span {
  position: absolute;
  bottom: -20px;
  left: 60px;
}

.zoom {
  width: max-content;
  color: #fff;
  font-size: 1.5em;
  position: absolute;
  top: 5px;
  right: 40px;
}

.zoom-in:hover, .zoom-out:hover {
  color: #007bff;
}

.zoom-blocked, .zoom-blocked:hover {
  color: #a9a9a9;
}

.animacion {
  height: 60vh;
  object-fit: cover;
  background-color: #000;
  background-image: url("bg-dark.e0b8eb03.jpg");
  display: flex;
}

.grafico {
  color: #fcfcf4;
  width: 100%;
  padding: 20px;
}

.grafico .contenido {
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0;
  display: flex;
  overflow: auto;
}

.img-fluid {
  flex: auto;
  transform: scale(.5);
}

.estado {
  width: 60%;
  text-align: center;
  opacity: .8;
  cursor: context-menu;
  background-color: #0a0a0a;
  border-bottom: 1px solid #2c5fac;
  border-radius: 0 0 20px 20px;
  justify-self: center;
  padding: 10px 15px;
  font-size: 1.1em;
  position: absolute;
}

.estado:hover {
  opacity: 1;
  transition: all .2s ease-in-out;
}

.contenedor {
  width: 85%;
  margin: 0 auto;
}

.cuadro {
  text-align: center;
  height: 80px;
  min-width: 80px;
  background-color: #27006e;
  border-bottom: 1px solid #4720f5;
  border-radius: 3px;
  flex-shrink: 0;
  margin: 20px;
  padding: 10px;
  font-size: 35px;
}

.cuadro small {
  color: #b4ddff;
  font-size: 17px;
}

.pivotLoc {
  outline-offset: 8px;
  outline: 2px solid #ffae00;
  font-weight: 700;
}

.cuadro:hover {
  cursor: context-menu;
  opacity: .8;
  transition: all .2s ease-in-out;
  position: relative;
  top: 3px;
}

.encontrado {
  background-color: #07b61e;
  border: 2px solid #00ff4c;
}

.buscando {
  background-color: #025baf;
  border: 2px solid #5f92ff;
}

.pivote {
  background-color: #c47f00;
  border: 2px solid #ff9d0b;
}

.descartado {
  color: #555;
  background-color: #6d0000;
  border: 2px solid #300;
}

.pseudo {
  background-color: #0e0e0e;
  border: 3px solid #000;
  border-radius: 10px;
  margin-top: 20px;
  padding: 15px 25px;
  font-family: Roboto Mono, monospace;
  font-size: .9rem;
}

.leyenda-q {
  align-items: center;
  display: flex;
}

#override .leyenda-q .grupo div {
  min-width: 0;
  width: 40px;
  height: 40px;
  margin: 2px;
  padding: 0;
}

#footer {
  text-align: center;
  margin-top: 2em;
  padding: 2em;
}

#footer a {
  color: #646464;
  font-family: monospace;
}

/*# sourceMappingURL=index.673fcd0b.css.map */
